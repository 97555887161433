const merchantQueries: object = {
  GET_MERCHANTS: (params: object) => {
    const query = `query GetMerchants($pageNumber:Int!, $pageSize:Int!) {
      merchants(pageNumber: $pageNumber, pageSize:$pageSize) {
        data {
          id
          name
          apiKey
          dateCreated
          dateModified
          userGroups {
            id
            groupDescription
          }
        }
        hasNextPage
        hasPreviousPage
        pageIndex
        totalPages
      }
    }`;

    return {
      operationName: null,
      query,
      variables:params
    }
  },
  GET_MERCHANT: (params: object) => {
    const query = `query GetMerchant($id: Int!) {
      merchant(id: $id) {
        id
        name
        dateCreated
        dateModified
        stores {
          id
          storeName
          storeCode
          storeUrl
          isActive
        }
        contacts {
          id
          firstName
          lastName
          emailAddress
          mobileNumber
          countryId
          regionId
          addressLine1
          addressLine2
          addressLine3
        }
        userGroups {
          id
          groupDescription
        }
      }
    }`;

    return {
      operationName: null,
      query,
      variables: params
    }
  },
  GET_MERCHANTS_FOR_USER: (params: object) => {
    const query = `query GetMerchantsForUser($userId: Int!) {
      merchantsForUser(userId: $userId) {
        id
        name
        apiKey
        dateCreated
        dateModified
        userGroups {
          id
          groupDescription
        }
      }
    }`;

    return {
      operationName: null,
      query,
      variables: params
    }
  }
}

export default merchantQueries as {
  [key: string]: Function;
}
