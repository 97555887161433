import React, { useContext } from 'react';
import { IOrderItemRecord } from 'types/order.types';
import { IUserType } from 'types/user.types';
import {IMetricType} from "../types/metric.types";

interface AuthContextType {
  currentUser: IUserType | null,
  isLoading: Boolean,
  orderMetricsData: IMetricType | null,
  createOrderData: IOrderItemRecord | null
}

export const AuthContext = React.createContext<AuthContextType>({
  currentUser: null,
  isLoading: false,
  orderMetricsData: null,
  createOrderData: null
});

export function useAuth() {
  return useContext(AuthContext);
}
