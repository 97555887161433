const orderMutations: object = {
  UPDATE_ORDER: (params: object) => {
    const query = `
      mutation UpdateOrder($data: UpdateOrderInput) {
        orders {
          update(order: $data) {
            id
            orderRef
            currencyCode
            isQuoteObject
            customerFirstName
            customerLastName
            customerPhoneNumber
            customerEmailAddress
            dateCreated
            dateModified
            basketAbandonmentTimestamp
            quotes {
              description
              price
              name
              qty
              sku
            }
            merchant {
              id
            }
            status {
              id
              statusName
            }
            user {
              id
              firstName
              lastName
            }
            reasonCode
          }
        }
      }`;

    return {
      operationName: null,
      query,
      variables: { data: params }
    };
  }
};

export default orderMutations as {
  [key: string]: Function;
};
