import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    //Todo: Log to an error reporting service like entry
  }

  handleClick = () => {
    window.location.href = '/dashboard';
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='text-center'>
          <h1>A customer potentially changed their mind. Please return to the Dashboard.</h1>
          <button onClick={this.handleClick} className='btn-sm btn-primary'>Return to dashboard</button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
