import * as JSZip from "jszip";
window['JSZip'] = JSZip;

export const TOKEN_ISSUE_TIME_KEYNAME = 'token_issue_time';
export const TOKEN_EXPIRE_TIME_KEYNAME = 'token_expire_time';
export const TOKEN_KEYNAME = 'token';
export const REFRESH_TOKEN_KEYNAME = 'refresh_token';
export const RECOVERSELL_GATEWAY = process.env.REACT_APP_GATEWAY_BASE_URL;
export const REACT_APP_FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const REACT_APP_FIREBASE_DB_URL = process.env.REACT_APP_FIREBASE_DB_URL;
export const REACT_APP_FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const REACT_APP_FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const REACT_APP_FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const REACT_APP_FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const REACT_APP_FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const REACT_APP_FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const ABANDONMENT_REASON_CODES = [
  {
    id: 'Website Error',
    codeName: 'Website Error'
  },
  {
    id: 'Difficult to navigate',
    codeName: 'Difficult to navigate'
  },
  {
    id: 'Unable to add flexi pay',
    codeName: 'Unable to add flexi pay'
  },
  {
    id: 'Payment declined',
    codeName: 'Payment declined'
  },
  {
    id: 'Account blocked',
    codeName: 'Account blocked'
  },
  {
    id: 'Unable to add DV/Promo',
    codeName: 'Unable to add DV/Promo'
  },
  {
    id: 'Unable to change card details on web',
    codeName: 'Unable to change card details on web'
  },
  {
    id: 'PayPal issue',
    codeName: 'PayPal issue'
  },
  {
    id: 'Unable to login',
    codeName: 'Unable to login'
  },
  {
    id: 'Website server down',
    codeName: 'Website server down'
  }
]

export const DECLINED_REASON_CODES = [
  {
    id: 'Changed their mind',
    codeName: 'Changed their mind'
  },
  {
    id: 'No funds',
    codeName: 'No funds'
  },
  {
    id: 'Expensive',
    codeName: 'Expensive'
  },
  {
    id: 'Looking elsewhere',
    codeName: 'Looking elsewhere'
  },
  {
    id: 'Was just browsing',
    codeName: 'Was just browsing'
  },
  {
    id: 'Will buy later',
    codeName: 'Will buy later'
  },
  {
    id: 'Need more time on the website',
    codeName: 'Need more time on the website'
  },
  {
    id: 'Product not available',
    codeName: 'Product not available'
  },
  {
    id: 'Delivery issue',
    codeName: 'Delivery issue'
  },
  {
    id: 'Call back arranged',
    codeName: 'Call back arranged'
  },
  {
    id: 'Doesn’t need assistance',
    codeName: 'Doesn’t need assistance'
  },
  {
    id: 'Items deleted from basket',
    codeName: 'Items deleted from basket'
  },
  {
    id: 'Busy',
    codeName: 'Busy'
  },
]

export const DATATABLE_OPTIONS = {
  'paging': true, // Table pagination
  'info': true, // Bottom left status text
  responsive: true,
  "dom": '<"dataViewSearchBtn"fB><"top">rt<"bottom"lp><"clear">',
  oLanguage: {
    sSearch: '',
    sLengthMenu: '_MENU_ records per page',
    info: 'Showing page _PAGE_ of _PAGES_',
    zeroRecords: 'Nothing found - sorry',
    infoEmpty: 'No records available',
    infoFiltered: '(filtered from _MAX_ total records)',
    oPaginate: {
      sNext: '<em class="fa fa-caret-right"></em>',
      sPrevious: '<em class="fa fa-caret-left"></em>'
    },
  },
  buttons: [
    { extend: 'csv', className: 'btn-info' },
    { extend: 'excel', className: 'btn-info' }
  ]
};

export const PAGINATION_TABLE_LIMIT = 1000;
