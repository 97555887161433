import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, RouteProps, Redirect } from 'react-router-dom';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';
import AuthenticatedRoute from './AuthenticatedRoute';
import AuthRoute from "./AuthRoute";

/* Used to render a lazy component with react-router */
const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => <Tag {...props} />;

const Dashboard = lazy(() => import('routes/Dashboard/Dashboard'));
const MerchantList = lazy(() => import('routes/MerchantManagement/MerchantList'));
const EditMerchant = lazy(() => import('routes/MerchantManagement/EditMerchant'));
const CreateMerchant = lazy(() => import('./routes/MerchantManagement/CreateMerchant'));
const MerchantDetailView = lazy(() => import('routes/MerchantManagement/MerchantDetailView'));
const OrderView = lazy(() => import('routes/OrderManagement/OrderList'));
const OrderDetailView = lazy(() => import('./routes/OrderManagement/OrderDetailView'));
const Login = lazy(() => import('./routes/Login/Login'));
const CreateUser = lazy(() => import('./routes/UserManagement/CreateUser'));
const UserList = lazy(() => import('./routes/UserManagement/UserList'));
const EditUser = lazy(() => import('./routes/UserManagement/EditUser'));
const CreateUserGroup = lazy(() => import('./routes/UserGroupManagement/CreateUserGroup'));
const UserGroupList = lazy(() => import('./routes/UserGroupManagement/UserGroupList'));
const EditUserGroup = lazy(() => import('./routes/UserGroupManagement/EditUserGroup'));
const AccountDetails = lazy(() => import('./routes/UserManagement/AccountDetails'));
const ForgotPassword = lazy(() => import('./routes/PasswordManagement/ForgotPassword'));
const ChangePassword = lazy(() => import('./routes/PasswordManagement/ChangePassword'))
const CreateExportLocation = lazy(()=> import('./routes/ReportingManagement/CreateExportLocation'));
const ExportLocationList = lazy(()=> import('./routes/ReportingManagement/ExportLocationList'));
const EditExportLocation = lazy(()=> import('./routes/ReportingManagement/EditExportLocation'));
const CreateScheduledExport = lazy(()=> import('./routes/ReportingManagement/CreateScheduledExport'));
const ScheduledExportList = lazy(()=> import('./routes/ReportingManagement/ScheduledExportList'));
const EditScheduledExport = lazy(()=> import('./routes/ReportingManagement/EditScheduledExport'));

const Routes = ({ location }: RouteProps) => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch location={location}>
        <Route exact path='/'>
          <Redirect to='/dashboard' />
        </Route>
        <AuthRoute path='/login' component={waitFor(Login)} />
        <AuthenticatedRoute path='/dashboard' component={waitFor(Dashboard)} />
        <AuthenticatedRoute path='/create-user' component={waitFor(CreateUser)} action='CREATE_USER' />
        <AuthenticatedRoute exact path='/users' component={waitFor(UserList)} />
        <AuthenticatedRoute exact path='/account-details' component={waitFor(AccountDetails)} />
        <AuthenticatedRoute path='/users/:id' component={waitFor(EditUser)}/>
        <AuthenticatedRoute path='/create-user-group' component={waitFor(CreateUserGroup)} action='CREATE_USER_GROUP' />
        <AuthenticatedRoute exact path='/user-groups' component={waitFor(UserGroupList)}  />
        <AuthenticatedRoute path='/user-groups/:id' component={waitFor(EditUserGroup)} action='EDIT_USER_GROUP' />
        <AuthenticatedRoute exact path='/merchants' component={waitFor(MerchantList)} />
        <AuthenticatedRoute exact path='/merchants/:id' component={waitFor(MerchantDetailView)} />
        <AuthenticatedRoute path='/merchants/:id/edit' component={waitFor(EditMerchant)} action='EDIT_MERCHANT' />
        <AuthenticatedRoute exact path='/orders' component={waitFor(OrderView)} />
        <AuthenticatedRoute path='/orders/:id' component={waitFor(OrderDetailView)} />
        <AuthenticatedRoute path='/create-merchant' component={waitFor(CreateMerchant)} action='CREATE_MERCHANT' />
        <AuthenticatedRoute path='/change-password' component={waitFor(ChangePassword)} />
        <AuthRoute path='/forgot-password' component={waitFor(ForgotPassword)} />
        <AuthenticatedRoute path='/create-export-location' component={waitFor(CreateExportLocation)} action='CREATE_EXPORT_LOCATION'/>
        <AuthenticatedRoute path='/export-locations' component={waitFor(ExportLocationList)}/>
        <AuthenticatedRoute path='/export-location/:id' component={waitFor(EditExportLocation)} action='EDIT_EXPORT_LOCATION'/>
        <AuthenticatedRoute path='/create-scheduled-export' component={waitFor(CreateScheduledExport)} action='CREATE_SCHEDULED_EXPORT'/>
        <AuthenticatedRoute path='/scheduled-exports' component={waitFor(ScheduledExportList)}/>
        <AuthenticatedRoute path='/scheduled-export/:id' component={waitFor(EditScheduledExport)} />

        <Route
          component={() => (
            <div style={{ textAlign: 'center' }}>
              <h1>Not Found</h1>
            </div>
          )}
        />
      </Switch>
    </Suspense>
  );
};

export default withRouter(Routes);
