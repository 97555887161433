import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
} from 'react-router-dom';
import * as signalR from "@microsoft/signalr";

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss';
import './styles/app/forms.scss';
import { AuthContext } from 'contexts/auth.context';
import { firebaseAuth } from 'services/firebase.service';
import StorageService from 'services/storage.service';
import {
  TOKEN_EXPIRE_TIME_KEYNAME,
  TOKEN_ISSUE_TIME_KEYNAME,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DB_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_API_KEY,
  RECOVERSELL_GATEWAY
} from './constants';
import {IUserType} from 'types/user.types';
import { getCurrentUserDetails } from 'services/user.service';
import { toast } from 'react-toastify';
import ErrorBoundary from 'components/Common/ErrorBoundary';
import firebase from 'firebase/app';
import 'firebase/analytics';
import {IMetricType} from "./types/metric.types";
import {getOrderMetrics} from "./services/metrics.service";
import { IOrderItemRecord } from 'types/order.types';

declare var PUBLIC_URL: string;

const App = () => {
  const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: REACT_APP_FIREBASE_DB_URL,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
    measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  firebase.analytics();

  const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');
  const [currentUser, setCurrentUser] = useState<IUserType | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orderMetricsData, setOrderMetricsData] = useState<IMetricType | null>(null);
  const [createOrderData, setCreateOrderData] = useState<IOrderItemRecord | null>(null);

  const showOrderToast = (message: string) => {
    toast.info(message, {
      position: 'bottom-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  useEffect(() => {
    if (currentUser) {
      getOrderMetrics()
        .then((res: IMetricType) => {
          setOrderMetricsData(res);
          setIsLoading(false);
        }).catch((err) => {
        toast.error('An error occurred while fetching daily metrics.');
      });
    }
  }, [currentUser]);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async (user) => {
      if (user !== null) {
        const userToken = await user.getIdTokenResult(
          true,
        );

        const tokenClaims:  {[p: string]: any} = userToken.claims;
        StorageService.setItem(TOKEN_ISSUE_TIME_KEYNAME, tokenClaims.auth_time.toString());
        StorageService.setItem(TOKEN_EXPIRE_TIME_KEYNAME, tokenClaims.exp.toString());
        StorageService.setToken(userToken.token);
        StorageService.setRefreshToken(user.refreshToken);
        setIsAuthenticated(true);
      } else {
        setCurrentUser(null);
        StorageService.clear();
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getCurrentUserDetails()
        .then(({ currentUser }) => {
          setCurrentUser(currentUser);
          StorageService.setItem('userId', currentUser.id)
        })
        .catch((e) => {
          toast.error('An error occurred while fetching user profile.');
        });

      // Builds the SignalR connection
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${RECOVERSELL_GATEWAY}/usernotifications`)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Error)
        .build();

      // listen to order message event
      hubConnection.on("order_info", (subject: Record<string, any>, message: string) => {
        let msg = '';
        const { createOrderData, updateOrderData, merchantData, orderMetricsData } = subject;
        if (createOrderData) {
          msg = `A new abandoned cart has been added for ${merchantData.name}`;
          setCreateOrderData(createOrderData);
        } else if (updateOrderData) {
          msg = `An abandoned cart with order reference - ${(updateOrderData.orderRef || 'N/A')} has been updated for ${merchantData.name}`
        }
        showOrderToast(msg);

        if (orderMetricsData) {
          setOrderMetricsData(orderMetricsData);
        }
      });

      // Starts the SignalR connection
      hubConnection.start().then(a => {
        // Once started, invokes the getConnectionId in NotificationHub.
        if (hubConnection.connectionId && StorageService.getItem('userId')) {
          hubConnection.invoke("getConnectionId", Number(StorageService.getItem('userId'))).catch(err => console.log(err));
        }
      });

      // Re-fetch connectionId on reconnect
      hubConnection.onreconnected(() => {
        if (hubConnection.connectionId && StorageService.getItem('userId')) {
          hubConnection.invoke("getConnectionId", Number(StorageService.getItem('userId'))).catch(err => console.log(err));
        }
      })
    }
  }, [isAuthenticated]);

  return (
    <ErrorBoundary>
      <AuthContext.Provider value={{ currentUser, isLoading, orderMetricsData, createOrderData }}>
        <BrowserRouter basename={basename}>
          <Routes/>
        </BrowserRouter>
      </AuthContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
