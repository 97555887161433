import { APP_CACHE_STORAGE_DRIVER } from '../config';
import { REFRESH_TOKEN_KEYNAME, TOKEN_KEYNAME } from '../constants';

export default {
  getItem(key: string) {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.getItem(key);
  },

  clear() {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.clear();
  },

  setItem(key: string, value: string) {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.setItem(key, value);
  },

  removeItem(key: string) {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.removeItem(key);
  },

  setToken(token: string) {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.setItem(TOKEN_KEYNAME, token);
  },

  setRefreshToken(token: string) {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.setItem(REFRESH_TOKEN_KEYNAME, token);
  },

  getToken(): string | null{
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.getItem(TOKEN_KEYNAME);
  },

  getRefreshToken(): string | null{
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.getItem(REFRESH_TOKEN_KEYNAME);
  },

  clearToken(): void {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    storage.removeItem(TOKEN_KEYNAME);
  },

  clearTokens(): void {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    storage.removeItem(TOKEN_KEYNAME);
    storage.removeItem(REFRESH_TOKEN_KEYNAME);
  }
};
