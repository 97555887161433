import React from 'react';
import rules from './rules';
import { useAuth } from 'contexts/auth.context';

interface IAccessControlType {
  action: string,
  children: any
}

export const hasAccess = (role: string, action: string): boolean => {
  if (!rules[action]) {
    return false;
  }
  return rules[action].roles.includes(role);
}

export const AccessControl: React.FC<IAccessControlType> = ({ action, children }) => {

  const { currentUser } = useAuth();

  if (!currentUser) {
    return null;
  }

  return hasAccess(currentUser.role, action) && children;
};
