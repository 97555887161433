const userGroupMutations: object = {
  CREATE_USER_GROUP: (params: object) => {
    const query = `
      mutation CreateUserGroup($data: CreateUserGroupInput!) {
        userGroups {
          create(userGroup: $data) {
            id
            groupDescription
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: { data: params }
    };
  },
  DELETE_USER_GROUP: (params: object) => {
    const query = `
      mutation DeleteUserGroup($data: DeleteUserGroupDto) {
        userGroups {
          delete(userGroup: $data) {
            status
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: { data: params }
    };
  },
  UPDATE_USER_GROUP: (params: object) => {
    const query = `
      mutation UpdateUserGroup($data: UpdateUserGroupInput) {
        userGroups {
          update(userGroup: $data) {
            groupDescription
            id
            members {
              id
              firstName
              lastName
            }
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: { data: params }
    };
  },
  CREATE_MERCHANT_GROUP_MAP: (params: object) => {
    const query = `
      mutation CreateMerchantGroupMap($data: CreateMerchantGroupMapInput) {
        userGroups {
          createMerchantGroupMap(merchantGroupMap: $data) {
            id
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: { data: params }
    };
  },
  DELETE_USER_FROM_GROUP: (params: object) => {
    const query = `
      mutation DeleteUserFromGroupMap($data: DeleteUserFromUserGroupInput) {
        userGroups {
          deleteUserFromUserGroup(userGroupMap: $data) {
            status
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: { data: params }
    };
  },
  DELETE_MERCHANT_FROM_GROUP: (params: object) => {
    const query = `
      mutation DeleteMerchantFromGroupMap($data: DeleteMerchantFromUserGroupInput) {
        userGroups {
          deleteMerchantFromUserGroup(merchantGroupMap: $data) {
            status
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: { data: params }
    };
  }
};

export default userGroupMutations as {
  [key: string]: Function;
};
