import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import queries from './queries';
import mutations from './mutations';

import { GraphQLPayload, IGraphQLError } from 'types/graphql.types';
import { GRAPHQL_ENDPOINT } from 'config';
import { noop } from 'libs/utils';
import StorageService from 'services/storage.service';

const client = axios.create({
  baseURL: GRAPHQL_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const refreshToken = async (options: AxiosRequestConfig) => {
  return axios({
    url: '',
    method: 'post',
    headers: { Authorization: StorageService.getRefreshToken(), ...options.headers },
  }).then((value: AxiosResponse) => {
    StorageService.setToken(value.data);
    /* eslint-disable-next-line no-param-reassign */
    options.headers.Authorization = `Bearer ${value.data}`;
  });
};

const setAuthorization = () => {
  client.interceptors.request.use(
    async (options: AxiosRequestConfig) => {
      const token = StorageService.getToken();
      if (token) {
        options.headers.Authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign
      } else {
        await refreshToken(options);
      }
      return options;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};

const extractError = () => {
  client.interceptors.response.use(undefined, async (error: AxiosError) => {
    return Promise.reject(error);
  });
};

setAuthorization();
extractError();

export default function graphqlFetch(reqName: string, params: object = {}): Promise<GraphQLPayload> {
  const getRequestBody: Function = queries[reqName] || mutations[reqName] || noop;

  return client({
    data: JSON.stringify(getRequestBody(params)),
    method: 'post',
  })
    .then((value: AxiosResponse<object>) => {
      return Promise.resolve({
        response: value.data,
      } as GraphQLPayload);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 && error.response.data.errors) { // GraphQL errors
          // Todo handle non-GraphQL errors (like network)
          const errors = error.response.data.errors.map((graphQLError: IGraphQLError) => {
            const { extensions } = graphQLError;
            // For errors from the APIs, the Gateway returns the original message in a data.Response field.
            const message = (extensions.data && extensions.data.Response) ? extensions.data.Response : graphQLError.message;
            return {
              code: graphQLError.extensions.code,
              message,
            };
          });
          return Promise.reject(errors);
        }
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ axiosError: error.message });
    });
}
