import { Role } from '../../types/user.types';

const rules: Record<string, any> = {
  CREATE_USER: {
    roles: [Role.ADMIN]
  },
  CREATE_USER_GROUP: {
    roles: [Role.ADMIN]
  },
  EDIT_USER_GROUP: {
    roles: [Role.ADMIN]
  },
  DELETE_USER: {
    roles: [Role.ADMIN]
  },
  EDIT_MERCHANT: {
    roles: [Role.ADMIN]
  },
  VIEW_MERCHANTS: {
    roles: [Role.ADMIN, Role.AGENT]
  },
  DASHBOARD: {
    roles: [Role.ADMIN, Role.AGENT]
  },
  VIEW_USERS: {
    roles: [Role.ADMIN]
  },
  VIEW_USER_GROUPS: {
    roles: [Role.ADMIN]
  },
  CREATE_MERCHANT: {
    roles: [Role.ADMIN]
  },
  VIEW_ORDERS: {
    roles: [Role.ADMIN, Role.AGENT]
  },
  VIEW_EXPORT_LOCATIONS:{
    roles: [Role.ADMIN]
  },
  CREATE_EXPORT_LOCATION:{
    roles: [Role.ADMIN]
  },
  EDIT_EXPORT_LOCATION:{
    roles: [Role.ADMIN]
  },
  VIEW_SCHEDULED_EXPORTS:{
    roles: [Role.ADMIN]
  },
  CREATE_SCHEDULED_EXPORT:{
    roles: [Role.ADMIN]
  },
}

export default rules;
