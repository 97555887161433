const userGroupQueries: object = {
  GET_USER_GROUPS: (params: object) => {
    const query = `query getUserGroups($pageNumber:Int!, $pageSize:Int!) {
      userGroups(pageNumber: $pageNumber, pageSize:$pageSize) {
      data {
        id
        groupDescription
        members {
          firstName
          lastName
        }
        }
        hasNextPage
        hasPreviousPage
        pageIndex
        totalPages
      }
    }`
    return {
      operationName: null,
      query,
      variables : params
    }
  },

  GET_USER_GROUP: (params: object) => {
    const query = `query GetUserGroup($id: Int!) {
      userGroup (id: $id) {
        id
        groupDescription
        members {
          firstName
          lastName
        }
      }
    }`
    return {
      operationName: null,
      query,
      variables: params
    }
  },
}

export default userGroupQueries as {
  [key: string]: Function;
}
