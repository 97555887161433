const countriesQueries: object = {
  GET_COUNTRIES: () => {
    const query = `query GetCountries {
      countries {
        id
        name
        regions {
          id
          name
        }
      }
    }`;

    return {
      operationName: null,
      query
    }
  }
}

export default countriesQueries as {
  [key: string]: Function;
}
