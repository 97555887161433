import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from 'config/firebase';
import 'firebase/storage';

firebase.initializeApp(firebaseConfig);

export const creationFirebaseAuth = firebase.initializeApp(firebaseConfig, 'Secondary')

export const firebaseAuth = firebase.auth();

const storage = firebase.storage();

export const store = storage.ref();
