const exportLocationQueries: object = {
  GET_EXPORT_LOCATION_DETAILS: (params: object) => {
    const query = `query exportLocation($id: Int!) {
      exportLocation(id:$id){
        id
        name
        location
        type
        dateCreated
      }
    }`
    return {
      operationName: null,
      query,
      variables: params
    }
  },

  GET_EXPORT_LOCATIONS: () => {
    const query = `query exportLocations {
      exportLocations{
        id
        name
        location
        type
        dateCreated
      }

    }`
    return {
      operationName: null,
      query,

    }
  },

}

export default exportLocationQueries as {
  [key: string]: Function;
}
