export interface ISidebarMenuItem {
	heading?: string;
	name?: string;
	icon?: string;
	translate?: string;
	path?: string;
	label?: {
		value: string | number;
		color: string;
	};
	submenu?: ISidebarMenu;
	action?: string
}

export interface ISidebarMenu extends Array<ISidebarMenuItem> { }

const Menu: ISidebarMenu = [
	{
		name: 'Dashboard',
		path: '/dashboard',
		icon: 'icon-screen-desktop',
		action: 'DASHBOARD'
	},
	{
		heading: 'Merchant Management',
    action: 'VIEW_MERCHANTS'
	},
	{
		name: 'Create Merchant',
		path: '/create-merchant',
		icon: 'icon-pencil',
		action: 'CREATE_MERCHANT'
	},
	{
		name: 'View Merchants',
		path: '/merchants',
		icon: 'icon-grid',
		action: 'VIEW_MERCHANTS'
	},
	{
		heading: 'User Management',
    action: 'VIEW_USERS'
	},
	{
		name: 'Create User',
		path: '/create-user',
		icon: 'icon-pencil',
		action: 'CREATE_USER'
	},
	{
		name: 'View Users',
		path: '/users',
		icon: 'icon-grid',
		action: 'VIEW_USERS'
	},
  {
    heading: 'User Group Management',
    action: 'VIEW_USER_GROUPS'
  },
  {
    name: 'Create User Group',
    path: '/create-user-group',
    icon: 'icon-pencil',
    action: 'CREATE_USER_GROUP'
  },
  {
    name: 'View User Groups',
    path: '/user-groups',
    icon: 'icon-grid',
    action: 'VIEW_USER_GROUPS'
  },
  {
    heading: 'Reporting Management',
    action: 'VIEW_EXPORT_LOCATIONS'
  },
  {
    name: 'Create Export Location',
    path: '/create-export-location',
    icon: 'icon-pencil',
    action: 'CREATE_EXPORT_LOCATION'
  },
  {
    name: 'View Export Locations',
    path: '/export-locations',
    icon: 'icon-grid',
    action: 'VIEW_EXPORT_LOCATIONS'
  },
  {
    name: 'Create Scheduled Export',
    path: '/create-scheduled-export',
    icon: 'icon-pencil',
    action: 'CREATE_SCHEDULED_EXPORT'
  },
  {
    name: 'View Scheduled Exports',
    path: '/scheduled-exports',
    icon: 'icon-grid',
    action: 'VIEW_SCHEDULED_EXPORTS'
  },
];

export default Menu;
