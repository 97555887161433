const metricQueries: object = {
  GET_ORDER_METRICS: (params: object) => {
    const query = `query getOrderMetrics{
      orderMetrics {
        totalOrders
        totalSales
        currencyCode
        merchants {
          merchantId
          totalOrders
          totalSales
          currencyCode
        }
        stores {
          storeCode
          totalOrders
          totalSales
          currencyCode
        }
        agents {
          userId
          totalSales
          totalOrders
        }
      }
    }`;

    return {
      operationName: null,
      query,
      variables: params
    }
  }
}

export default metricQueries as {
  [key: string]: Function;
}