const userQueries: object = {
  GET_USER_DETAILS: () => {
    const query = `query currentUser {
      currentUser {
        id
        emailAddress
        lastName
        firstName
        firebaseId
        role
        userGroups {
          id
          groupDescription
        }
      }
    }`
    return {
      operationName: null,
      query
    }
  },

  GET_USERS: (params: object) => {
    const query = `query users($pageNumber:Int!, $pageSize:Int!) {
      users(pageNumber: $pageNumber, pageSize:$pageSize) {
      data {
        id
        emailAddress
        lastName
        firstName
        role
        isActive
        dateCreated
        firebaseId
        userGroups {
          id
          groupDescription
        }
        }
      hasNextPage
      hasPreviousPage
      pageIndex
      totalPages
      }
    }`
    return {
      operationName: null,
      query,
      variables: params
    }
  },

  GET_USER: (params: object) => {
    const query = `query GetUser($id: Int!) {
      user (id: $id) {
        id
        emailAddress
        lastName
        firstName
        role
        isActive
        dateCreated
        firebaseId
        userGroups {
          id
          groupDescription
        }
      }
    }`
    return {
      operationName: null,
      query,
      variables: params
    }
  },

  IS_EMAIL_AVAILABLE: (params: object) => {
    const query = `query isEmailAvailable($email: String!) {
      isEmailAvailable(email: $email) {
        isAvailable
      }
    }`
    return {
      operationName: null,
      query,
      variables: params
    }
  },

  IS_USERNAME_AVAILABLE: (params: object) => {
    const query = `query isUsernameAvailable($username: String!) {
      isUsernameAvailable(username: $username) {
        isAvailable
      }
    }`
    return {
      operationName: null,
      query,
      variables: params
    }
  }
}

export default userQueries as {
  [key: string]: Function;
}
