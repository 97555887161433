import { Omit } from "react-router";
import {Link, Route, RouteProps} from "react-router-dom";
import React from "react";
import ContentWrapper from "./components/Layout/ContentWrapper";
import recoversellLogo from "./images/customerassist-logo.png";
import CustomToastContainer from "./components/Common/Toast";

interface AuthRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ component: Component, ...rest }) => {

  return (
    <Route exact
           {...rest}
           render={props =>
             (
               <div className='auth-container'>
                 <CustomToastContainer />
                 <ContentWrapper>
                   <div className='col-md-6 offset-md-3'>
                     <Link to='/login'>
                       <img
                         alt=''
                         src={recoversellLogo}
                       />
                     </Link>
                     <Component {...props} />
                   </div>
                 </ContentWrapper>
               </div>
             )
           }
    />
  );
}

export default AuthRoute;
