import {
  GET_ORDER_BY_ID_STARTED,
  GET_ORDER_BY_ID_FINISHED,
  UPDATE_ORDER_STARTED,
  UPDATE_ORDER_FINISHED,
  OrderAction,
  OrderState,
} from '../actions/order.actions';

const initialState: OrderState = {
  isFetching: false,
  order: null,
};

const orderReducer = (state = initialState, action: OrderAction): OrderState => {
  let newState;

  switch (action.type) {
    case GET_ORDER_BY_ID_STARTED:
    case UPDATE_ORDER_STARTED:
      return {
        ...initialState,
        isFetching: true,
      } as OrderState;

    case GET_ORDER_BY_ID_FINISHED:
    case UPDATE_ORDER_FINISHED:
      newState = {
        ...state,
        isFetching: false,
        order: action.payload,
      };

      return newState as OrderState;

    default:
      return state;
  }
};

export default orderReducer;
