import graphqlFetch from '../graphql';
import { IPasswordInputType, IUserInputType } from '../types/user.types';
import {PaginationParamsType} from "../types/generic.types";

export const getCurrentUserDetails = () => {
  return graphqlFetch('GET_USER_DETAILS')
    .then((res: any) => {
      return res.response.data;
    })
    .catch((err: any) => {
      throw err
    })
}

export const getUsers = (data:PaginationParamsType) => {
  return graphqlFetch('GET_USERS', data)
    .then((res: any) => {
      return res.response.data.users || [];
    })
    .catch((err: any) => {
      throw err
    })
}

export const getUser = (data: { id:  number }) => {
  return graphqlFetch('GET_USER', data)
    .then((res: any) => {
      return res.response.data || [];
    })
    .catch((err: any) => {
      throw err
    })
}

export const createUser = (data: IUserInputType) => {
  return graphqlFetch('CREATE_USER', data)
    .then((res: any) => {
      return res.response.data;
    })
    .catch((err: any) => {
      throw err
    })
}

export const editUser = (data: IUserInputType) => {
  return graphqlFetch('EDIT_USER', data)
    .then((res: any) => {
      return res.response.data;
    })
    .catch((err: any) => {
      throw err
    })
}

export const deleteUser = (data: { id: number }) => {
  return graphqlFetch('DELETE_USER', data)
    .then((res: any) => {
      return res.response.data;
    })
    .catch((err: any) => {
      throw err
    })
}

export const isEmailAvailable = (email: string) => {
  return graphqlFetch('IS_EMAIL_AVAILABLE', { email })
    .then((res: any) => {
      return res.response.data;
    })
    .catch((err: any) => {
      throw err
    })
}

export const isUsernameAvailable = (username: string) => {
  return graphqlFetch('IS_USERNAME_AVAILABLE', { username })
    .then((res: any) => {
      return res.response.data;
    })
    .catch((err: any) => {
      throw err
    })
}

export const resetPassword = (data: IPasswordInputType) => {
  return graphqlFetch('RESET_PASSWORD', data)
    .then((res: any) => {
      return res.response.data;
    })
    .catch((err: any) => {
      throw err
    })
}
