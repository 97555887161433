import userMutations from './users';
import merchantMutations from './merchants';
import orderMutations from './orders';
import userGroupMutations from './groups';
import exportLocationMutations from "./exportLocation";
import scheduledExportMutations from "./scheduledExport";

export default {
  ...userMutations,
  ...merchantMutations,
  ...orderMutations,
  ...userGroupMutations,
  ...exportLocationMutations,
  ...scheduledExportMutations,
}
