import React from 'react';

import Header from './Header';
import Sidebar from './Sidebar';

import CustomToastContainer from 'components/Common/Toast';
import { IUserType } from 'types/user.types';

interface IBasePropsType {
  currentUser: IUserType
}

const Base: React.FC<IBasePropsType> = ({ currentUser, children }) => (
    <div className="wrapper">
      <Header />

      <Sidebar currentUser={currentUser} />
        <section className="section-container">
          <CustomToastContainer />
          {children}
        </section>
    </div>
);

export default Base;
