import userQueries from './users';
import merchantQueries from './merchants';
import storeQueries from './stores';
import countryQueries from './countries';
import orderQueries from './orders';
import statusQueries from './statuses';
import userGroupQueries from './groups';
import exportLocationQueries from './exportLocations'
import scheduledExportQueries from './scheduledExports'
import metricQueries from './metrics';

export default {
  ...userQueries,
  ...merchantQueries,
  ...storeQueries,
  ...countryQueries,
  ...orderQueries,
  ...statusQueries,
  ...userGroupQueries,
  ...exportLocationQueries,
  ...scheduledExportQueries,
  ...metricQueries,
}
