const orderQueries: object = {
  GET_ORDERS: (params: object) => {
    const query = `query GetOrders($pageNumber: Int!, $pageSize: Int!) {
      orders(pageNumber: $pageNumber, pageSize: $pageSize) {
        data {
          id
          basketAbandonmentTimestamp
          customerEmailAddress
          customerFirstName
          customerLastName
          customerPhoneNumber
          orderRef
          orderTotal
          currencyCode
          isQuoteObject
          merchant {
            id
          }
          status {
            id
            statusName
          }
          user {
            id
            firstName
            lastName
          }
        }
        hasNextPage
        hasPreviousPage
        pageIndex
        totalPages
      }
    }`;

    return {
      operationName: null,
      query,
      variables: params
    }
  },
  GET_ORDER_BY_ID: (params: object) => {
    const query = `query GetOrderById($id: Int!) {
      order(id: $id) {
        id
        basketAbandonmentTimestamp
        customerEmailAddress
        customerFirstName
        customerLastName
        customerPhoneNumber
        orderRef
        orderTotal
        currencyCode
        isQuoteObject
        merchant {
          id
        }
        quotes {
          id
          description
          finalPrice
          isInStock
          maxPrice
          minPrice
          name
          orderId
          price
          productOptions
          qty
          sku
          typeId
        }
        status {
          id
          statusName
        }
        user {
          id
          firstName
          lastName
        }
        reasonCode
      }
    }`;

    return {
      operationName: null,
      query,
      variables: params
    }
  },
  GET_ORDERS_BY_MERCHANT_ID: (params: object) => {
    const query = `query GetOrdersByMerchantId($id: Int!) {
      ordersByMerchant(merchantId: $id) {
        id
        basketAbandonmentTimestamp
        customerEmailAddress
        customerFirstName
        customerLastName
        customerPhoneNumber
        orderRef
        orderTotal
        currencyCode
        isQuoteObject
        merchant {
          id
        }
        status {
          statusName
        }
        user {
          id
          firstName
          lastName
        }
        reasonCode
      }
    }`;

    return {
      operationName: null,
      query,
      variables: params
    }
  }
}

export default orderQueries as {
  [key: string]: Function;
}
