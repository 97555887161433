import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer';
import themesReducer from './themes.reducers';
import ordersReducer from './orders.reducer';
import orderReducer from './order.reducer';

export default combineReducers({
    settings: settingsReducer,
    theme: themesReducer,
    orders: ordersReducer,
    order: orderReducer,
});
