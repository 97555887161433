const statusQueries: object = {
  GET_STATUSES: () => {
    const query = `query GetStatuses {
      statuses {
        id
        statusName
      }
    }`;

    return {
      operationName: null,
      query
    }
  }
}

export default statusQueries as {
  [key: string]: Function;
}
