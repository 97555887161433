import { IUpdateOrderInput } from 'types/order.types';
import graphqlFetch from '../graphql';
import {PaginationParamsType} from "../types/generic.types";

export const getOrders = (data:PaginationParamsType) => {
  return graphqlFetch('GET_ORDERS', data)
    .then((res: any) => {
      return res.response.data.orders || [];
    })
    .catch((err: any) => {
      throw err
    })
}

export const getOrderById = (data: { id: number }) => {
  return graphqlFetch('GET_ORDER_BY_ID', data)
    .then((res: any) => {
      return res.response.data.order || [];
    })
    .catch((err: any) => {
      throw err
    })
}

export const getOrdersByMerchantId = (data: { id: number }) => {
  return graphqlFetch('GET_ORDERS_BY_MERCHANT_ID', data)
    .then((res: any) => {
      return res.response.data.ordersByMerchant;
    })
    .catch((err: any) => {
      throw err
    })
}

export const updateOrder = (data: IUpdateOrderInput) => {
  return graphqlFetch('UPDATE_ORDER', data)
    .then((res: any) => {
      return res.response.data.orders.update;
    })
    .catch((err: any) => {
      throw err
    })
}
