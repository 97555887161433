import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

interface INowType {
  format: string,
  className?: string
}

/**
 * Updates every second the content of the element
 * with the current time formatted
 */
const Now: React.FC<INowType> = ({ format, className }) => {
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const updateTime = () => {
      setCurrentTime(moment.tz(new Date(), 'Europe/London').format(format))
    }

    setInterval(updateTime, 1000)
  }, [format]);

  return (
    <div style={{ display: 'inline-block' }} className={className}>
      {currentTime}
    </div>
  )
  
}

export default Now;